.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 10px !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent
    url("https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png")
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}

.select-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.75rem;
  border-left: 0.15rem solid transparent;
  border-right: 0.15rem solid transparent;
  border-top: 0.15rem solid currentColor;
  transition: transform 0.2s;
}

.select-wrapper:hover::after {
  transform: translateY(-50%) rotate(180deg);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
